export const useTransform = () => {
  function transform(data: any) {
    let pageHits = data.pageHits.PageHit
    const results = pageHits.map((hit: any) => {
      // Transform each hit according to the specified structure.
      // Adjust the paths as necessary based on your actual data structure.
      return {
        id: {
          document: hit.docId,
          page: hit.pageNr,
        },
        meta: {
          title: hit.docTitle,
          page: hit.pageNr,
          collections: hit.collectionIds.collectionId,
        },
        image: {
          id: hit.pageUrl,
          width: 5409,
          height: 4594,
        },
        results: hit.wordCoords.map(parseWordCoords),
        items: hit.highlights.map(parseHighlight),
      }
    })

    // Log and return the transformed result along with pagination info
    // console.log('transformed result ', {
    //   offset: data.start,
    //   limit: data.rows,
    //   total: data.numResults,
    //   items: results,
    // })

    return {
      offset: data.start,
      limit: data.rows,
      total: data.numResults,
      items: results,
    }
  }

  // This function takes a string input and returns an object with parsed values.
  function parseWordCoords(string: string) {
    // Splitting the string with ':' separator and storing it in 'parts' variable.
    const parts: string[] = string.split(':')

    // Extracting line id from the second element of 'parts' array by splitting it using '/' separator.
    const lineId: string[] = parts[1].split('/')

    // Extracting coordinates from the third element of 'parts' array by splitting using ' ' separator.
    let coords: string[] = parts[2].split(' ')

    // Mapping the 'coords' array into an array of objects with 'x' and 'y' keys.
    const points = coords.map((point: string) => {
      const coord: { x: string; y: string } = { x: '', y: '' }
      let split: string[] = point.split(',')
      coord.x = split[0]
      coord.y = split[1]
      return coord
    })

    // Converting the array of objects to a rectangle object using 'toRect' function.
    let rect = toRect(points)

    // Creating an object with extracted values and returning it.
    const result = { id: lineId[2], label: parts[0], rect: rect }
    return result
  }

  function toRect(points: { x: number; y: number }[]) {
    const xMin = Math.min(...points.map((p: { x: number; y: number }) => p.x))
    const xMax = Math.max(...points.map((p: { x: number; y: number }) => p.x))
    const yMin = Math.min(...points.map((p: { x: number; y: number }) => p.y))
    const yMax = Math.max(...points.map((p: { x: number; y: number }) => p.y))

    return {
      x: xMin,
      y: yMin,
      width: xMax - xMin,
      height: yMax - yMin,
    }
  }

  function parseHighlight(string: string) {
    const parts = string.split(/<em>|<\/em>/)
    const result = []
    /* NOTE:
     *
     * When encountering '', ignore it, and flip wasMatch. First string
     * encountered should be considered non-match.
     *
     */
    let wasMatch = true
    while (parts.length > 0) {
      const string = parts.shift()
      if (string !== '' && wasMatch) {
        result.push({ string })
      } else if (string !== '') {
        result.push({
          isMatch: true,
          string,
        })
      }
      wasMatch = !wasMatch
    }
    return result
  }

  return {
    transform,
  }
}
